.gallery {    
  margin-left: 5vw;     
  margin-right: 5vw;
  //margin-top: 1vw;  
}    

.zoom {      
  -webkit-transition: all 0.35s ease-in-out;    
  -moz-transition: all 0.35s ease-in-out;    
  transition: all 0.35s ease-in-out;     
  cursor: -webkit-zoom-in;      
  cursor: -moz-zoom-in;      
  cursor: zoom-in;  
}     

.zoom_2 {      
  -webkit-transition: all 0.35s ease-in-out;    
  -moz-transition: all 0.35s ease-in-out;    
  transition: all 0.35s ease-in-out;     
  cursor: -webkit-zoom-in;      
  cursor: -moz-zoom-in;      
  cursor: zoom-in;  
} 

.zoom:hover,  
.zoom:active,   
.zoom:focus {
  /**adjust scale to desired size, add browser prefixes**/
  -ms-transform: scale(2.0);    
  -moz-transform: scale(2.0);  
  -webkit-transform: scale(2.0);  
  -o-transform: scale(2.0);  
  transform: scale(1.5);    
  position:relative;      
  z-index:100;  
  //margin-right: 5vw;
  //margin-top: 5vw;
}

.zoom_2:hover,  
.zoom_2:active,   
.zoom_2:focus {
  /**adjust scale to desired size, add browser prefixes**/
  -ms-transform: scale(2.0);    
  -moz-transform: scale(2.0);  
  -webkit-transform: scale(2.0);  
  -o-transform: scale(2.0);  
  transform: scale(1.2);    
  position:relative;      
  z-index:100;  
  //margin-right: 5vw;
  //margin-top: 5vw;
}

/**To keep upscaled images visible on mobile, increase left & right margins a bit**/  
@media only screen and (max-width: 768px) {   
  .gallery {      
    margin-left: 15vw;       
    margin-right: 15vw;
  }

  /**TIP: Easy escape for touch screens,give gallery's parent container a cursor: pointer.**/
  .DivName {
    cursor: pointer
  }
}